import React from "react";
import EpkPage from "./EpkPage";
import { Container, Row, Col, Button, Form, FormGroup, Label, Alert } from 'reactstrap';
import { withRouter } from "../../common/components/routing/Router";
import { Path } from "../container/Path";
import { Container as PageContainer } from "../../index";
import { IDestroy } from "../../common/components/pages/IDestroy";
import { TextInput } from '../../common/components/widgets/form/input/TextInput';
import { EmailInput } from "../../common/components/widgets/form/input/EmailInput";
import { TextAreaInput } from "../../common/components/widgets/form/input/TextAreaInput";
import { Contact, IContact } from "../models/Contact";
import { AxiosError, AxiosResponse } from 'axios';
import Util from "../custom/Util";
import ReCAPTCHA from "react-google-recaptcha";

@withRouter(Path.CONTACT.toString(), PageContainer)
export default class ContactPage extends EpkPage {

    private submitted: boolean = false;

    private contact: Contact = new Contact();

    private formRef: React.RefObject<any> = React.createRef();

    private recaptchaRef: React.RefObject<any> = React.createRef();

    constructor(props: any) {
        super(props);

        this.state = {
            contact: this.contact,
            captchaExpired: false,
            captchaValue: ""
        };
        if (typeof window !== 'undefined') {
            window['recaptchaOptions'] = {
                lang: 'hr',
            };
        }
    }

    private contactInputs: { [attr: string]: React.RefObject<any> } = {
        name: React.createRef(),
        municipality: React.createRef(),
        phone: React.createRef(),
        email: React.createRef(),
        message: React.createRef(),
    };

    pageTitle() {
        return "Kontakt" + super.pageTitle();
    }

    getDestroyableMembers(): IDestroy[] {
        return [
            this.contact
        ];
    }

    private readInfo(): IContact {
        const plainObject: IContact = {};

        for (const key in this.contactInputs) {

            if (this.contactInputs.hasOwnProperty(key) && this.contactInputs[key].current) {
                plainObject[key] = this.contactInputs[key].current.getValue();
                this.contactInputs[key].current.removeError();
            }
        }

        plainObject.captcha = this.state.captchaValue;

        this.contact.setFromPlainObject(plainObject);

        return plainObject;
    }

    private handleValidate(event: any): boolean {
        this.readInfo();
        const valid = this.contact.validate();

        if (this.submitted) {
            if (!valid) {
                const errors: { [attr: string]: string } = this.contact.getErrors();
                this.fillInputsWithErrors(errors);
            }
        }

        event.preventDefault();

        return valid;
    }

    private fillInputsWithErrors(errors: { [attr: string]: string }) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                this.contactInputs[key].current.setError(errors[key]);
            }
        }
    }

    protected handleSubmit(event: React.MouseEvent<HTMLElement>): void {

        if (!this.state.captchaValue) {
            Util.notification("error", "Molimo potvrdite da niste robot.", 1500);
            event.preventDefault();
            return;
        }

        if (this.state.captchaExpired) {
            Util.notification("error", "Molimo ponovo potvrdite da niste robot.", 1500);
            event.preventDefault();
            return;
        }

        this.submitted = true;

        const valid: boolean = this.handleValidate(event);
        const formElement = this.formRef.current as HTMLFormElement;

        if (valid) {
            this.contact.send().then((response: AxiosResponse) => {
                Util.notification("success", "Poruka uspješno poslata.", 1500);
                formElement.reset();
                this.recaptchaRef.current.reset();
                this.submitted = false;
            }).catch((error: AxiosError) => {
                Util.notification("error", "Došlo je do greške.", 1500);
            });
        }

        event.preventDefault();
    }

    private onRecaptchaChange(val: string | null) {
        if (val === null) {
            this.setState({ captchaExpired: true });
        }

        this.setState({ captchaValue: val });
    }

    renderContent() {
        return (
            <div className="epk-contact-content">
                <Container>
                    <div className="epk-contact-content-section epk-section">
                        <Row>
                            <Col md="12">
                                <h1 className="epk-container-h1">Kontakt</h1>
                            </Col>
                            <Col md="6">
                                <Alert color="empty">
                                    EKI službenici su vam na usluzi za sva pitanja i nejasnoće. Molimo vas da popunite obrazac sa svim potrebnim podacima i javićemo vam se u najkraćem mogućem roku. </Alert>
                                <Form innerRef={this.formRef}>
                                    <FormGroup>
                                        <Label for="contact-name">Ime i prezime</Label>
                                        <TextInput tabIndex={8} id="contact-name" ref={this.contactInputs.name} onBlur={this.handleValidate.bind(this)} autoFocus={true} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="contact-municipality">Opština</Label>
                                        <TextInput tabIndex={9} id="contact-municipality" ref={this.contactInputs.municipality} onBlur={this.handleValidate.bind(this)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="contact-phone">Telefon</Label>
                                        <TextInput tabIndex={10} id="contact-phone" ref={this.contactInputs.phone} onBlur={this.handleValidate.bind(this)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="contact-email">E-mail</Label>
                                        <EmailInput tabIndex={11} id="contact-email" ref={this.contactInputs.email} onBlur={this.handleValidate.bind(this)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <TextAreaInput tabIndex={12} label="Poruka" rows={3} id="contact-message" ref={this.contactInputs.message} />
                                    </FormGroup>
                                    <FormGroup>
                                        <ReCAPTCHA
                                            ref={this.recaptchaRef}
                                            tabIndex={13}
                                            sitekey="6LctUVkUAAAAADrTqb-_ohD_ZTxftSj6nACg1iFA"
                                            onChange={this.onRecaptchaChange.bind(this)}
                                        />
                                    </FormGroup>
                                    <Button tabIndex={14} color="epk-primary" type="submit" onClick={this.handleSubmit.bind(this)}>Pošalji</Button>
                                </Form>
                            </Col>
                            <Col md="6">
                                <div>
                                    <img className="img-fluid" src="/img/register-banner.jpg" />
                                    <div className="epk-contact-right">
                                        <p>Vaša pitanja možete poslati i na mail: <a href="mailto:poslovniklub@eki.ba">poslovniklub@eki.ba</a>
                                            <br></br>
                                            Besplatna info linija: 080 051 052</p>

                                        <p><a target="_blank" href="https://www.eki.ba/mkd-kontakt/">Mreža EKI poslovnica</a></p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}
